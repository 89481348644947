.ChatStarter {
  &-title {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 0.25em;
  }

  &-edit {
    text-decoration: none;
    display: inline-flex;
    background: transparent;
    border: 0;
    opacity: 1;
    transition: opacity 0.3s;

    > * {
      height: 100%;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &-edit {
    height: 1rem;
  }

  &-description {
    padding: 0 1em;
  }

  &-form {
    margin-top: auto;
  }
}
