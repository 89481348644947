.PromptsStarterForm {
  --SenderLayout-color: var(--colorContrast);
  --SenderLayout-bg: var(--colorDefault);

  line-height: 1.5;
  color: var(--colorContrast);

  code {
    overflow: auto;
    max-height: 20em;
    white-space: pre-wrap;
    padding: 0.5em;
  }
}
