@import url("./utils/github.markdown-dark.css");

:root {
  --color-0: #fff;
  --color-65: #282c34;
  --color-70: #272d2e;
  --color-90: #131315;

  --colorContrast: var(--color-0);
  --colorDefault: var(--color-65);
  --colorError: #a44;
  --colorError--contrast: #faa;
  --chatRootBg: var(--color-90);
  --dropdownBg: var(--color-70);
  --spacingInline: 4em;
  --accentColor: #d7fe63;
  --fontFamily--primary: Montserrat, sans-serif;
}

body {
  --toastify-color-success: var(--accentColor);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-error: var(--colorError--contrast);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-color-progress-error: var(--colorError);

  color-scheme: dark;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--colorDefault);
}

a,
button:not(:disabled) {
  cursor: pointer;
}

label,
textarea,
input {
  font-family: var(--fontFamily--primary);
  font-size: 0.975em;
}

svg {
  height: var(--iconSize, 1em);
}

body > iframe {
  /* display: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* reset */
html {
  height: 100vh;
  height: 100svh;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

html,
body,
#root {
  overflow: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

li {
  padding: 0.125em;
}

ul {
  padding-inline-start: 1.5em;
}
