.ChatQuestionForm {
  &:has(textarea:read-only) {
    --SenderLayout-bg: #f2f2f2;
    --SenderLayout-color: #828282;
  }

  textarea {
    color-scheme: light;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    color: inherit;
    font-size: 1.125rem;
    font-family: inherit;
    line-height: 1.375rem;
    padding: 0.5rem;
  }
}
