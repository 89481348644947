.Tile {
  --fontSize: var(--Tile-fontSize, 2em);

  padding: 1em 1.5em 1.5em;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
  height: 100%;

  &:hover {
    color: var(--textColor);
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-userIcon {
    width: 2em;
    height: 2em;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
  }

  &-arrow {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    margin-inline-start: auto;
  }

  &-arrowImage {
    width: 1rem;
    height: 1rem;
    color: #2d3648;
  }

  &-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
  }

  &-title {
    font-size: var(--fontSize);
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    margin-block-start: 0.5em;
  }
}
