@use "./utils/index.scss";

.App {
  --appBgColor: var(--colorDefault);
  --appBgSpeed: 0.5s;
  --sidebarAnimationSpeed: 0.5s;

  container-type: inline-size;
  container-name: app;
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: var(--appBgColor);
  background-color: var(--chatRootBg);
  color: #bbb8b8;
  overflow: auto;
  transition: background-color var(--appBgSpeed);

  &:has(.Chat) {
    --appBgColor: var(--chatRootBg);
  }

  &-sidebar {
    --sidebarWidth: 4em;

    padding: 2em 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: var(--sidebarWidth);
    background-color: var(--chatRootBg);
    transition:
      background-color var(--appBgSpeed),
      flex-basis var(--appBgSpeed);
  }

  &-nav {
    margin-block: auto;
  }

  &-navList {
    list-style: none;
    padding: 2em;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 4em;
  }

  &-navLink {
    display: flex;
    gap: 0;
    align-items: center;
    color: #aaa;
    text-decoration: none;
    transition: gap var(--sidebarAnimationSpeed);

    &.active {
      color: #fff;
    }
  }

  &-navIcon {
    width: 1.5em;
    height: 1.5em;
  }

  &-navText {
    font-size: 1.25em;
    font-weight: bold;
    line-height: 1.5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 0;
    opacity: 0;
    transition:
      max-width var(--sidebarAnimationSpeed),
      opacity var(--sidebarAnimationSpeed);
  }

  &-sidebar[data-active]:hover &-navText {
    max-width: 20em;
    opacity: 1;
  }

  &-sidebar[data-active]:hover &-navLink {
    gap: 1em;
  }

  &-body {
    margin-inline-end: var(--spacingInline);
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    transition: filter 0.3s;
  }

  &[data-no-agreement] &-body {
    filter: blur(0.25em);
  }

  &-userAgreement {
    position: fixed;
    inset: 0;
    background-color: #1a1a1aaa;
    display: flex;
    flex-direction: column;
    padding: 8em;
    overflow: auto;

    &Body {
      background: var(--chatRootBg);
      padding: 1.5em;
      border-radius: 2em;
      box-shadow: 0 0 1em rgba(0, 0, 0, 0.7);
      margin-inline: auto;
    }
  }

  &-logout {
    @extend %button--text;

    --iconSize: 1.5em;

    justify-content: unset;
    padding-inline: 2em;
    transition: color 0.3s;

    &:hover {
      color: #fff;
    }
  }
}

@container app (max-width: 768px) {
  .App {
    &-sidebar {
      display: none;
    }

    &-body {
      --spacingInline: 0em;
    }

    &-userAgreement {
      padding: 0;

      &Body {
        height: 100%;
      }
    }
  }
}

a {
  color: #ccc;

  &:hover {
    color: #eee;
  }
}
