%button--text {
  background-color: transparent;
  border: none;
  outline: none;
  color: inherit;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  line-height: inherit;
}
