.UserAgreement {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  width: fit-content;
  overflow: auto;
  font-size: 1.25em;

  &-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &-text,
  &-error,
  &-loading {
    background-color: var(--colorDefault);
    background-color: #191d1e;
    padding: 0.75em 1.5em;
    border-radius: 1em;
    max-width: 40em;
  }

  &-loading {
    display: flex;
    gap: 1em;
  }

  &-actions {
    display: flex;
    margin-block-start: auto;
    padding: 1em 1em 0.5em;
    gap: 4em;
  }

  &-checkbox {
    display: flex;
    gap: 0.5em;
    align-items: center;
    font-size: inherit;
  }

  &-continueButton {
    margin-inline-start: auto;
    padding: 0.5em 1em;
    border-radius: 1em;
    border: none;
    box-shadow: 0 0 0.5em rgba(120, 120, 120, 0.5);
    display: flex;
    gap: 0.5em;
    align-items: center;
    font-size: inherit;
    transition:
      background-color 0.5s,
      color 0.5s,
      box-shadow 0.5s;

    &:not(:disabled) {
      box-shadow: 0 0 0.5em #d7fe63cc;
      background-color: var(--accentColor);
      color: #000;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &-arrow {
    height: 0.5em;
    transform: rotate(45deg);
  }

  &-error {
    color: var(--colorError--contrast);
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
  }

  &-errorIcon {
    width: 1.25em;
    height: 1.25em;
    background: var(--colorError);
    color: #fff;
    border-radius: 50%;
    padding: 0.125em;
    align-self: flex-start;
    flex-shrink: 0;
  }
}

@media (max-width: 768px) {
  .UserAgreement {
    font-size: 1em;
  }
}
