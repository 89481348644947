.TileAnimateable {
  --_animationDuration: var(--animationDuration, 0.3s);
  --backAngle--closed: 0deg;
  --backAngle--open: -180deg;
  --backColor--closed: #fff;
  --backColor--open: var(--accentColor);
  --backContainerColor: var(--chatRootBg);
  --bgColor: #191d1e;
  --borderRadius: 3em;
  --padding: 1em 1.5em 1.5em 1.5em;
  --sendColor: #191d1e;
  --titleInputPadding: 0.5em 0.75em;
  --titlePadding: 0.5em 0.5em 0.5em 2em;

  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  font-family: var(--fontFamily--primary);
  background-color: var(--bgColor);
  padding: var(--padding);
  border-radius: var(--borderRadius);
  position: absolute;
  inset: 0;
  scroll-behavior: smooth;
  animation-duration: var(--_animationDuration);
  animation-fill-mode: both;
  isolation: isolate;

  &[data-animation-name="slideout"] {
    animation-name: chat-fadeout;
    animation-timing-function: ease-out;
  }

  &[data-animation-name="slidein"] {
    animation-name: chat-fadein;
    animation-timing-function: ease-in;
  }

  &-header {
    display: flex;
    isolation: isolate;

    // make back button visible on the chat screen
    // hopefully, this will be fixed in the future
    // by moving the chat title to the tile component
    z-index: 1;
    pointer-events: none;

    > * {
      pointer-events: auto;
    }
  }

  &-back {
    --extraCornerSize: 0.5em;

    background-color: var(--backContainerColor);
    width: min-content;
    place-self: flex-end;
    padding: 1em;
    border-bottom-left-radius: var(--borderRadius);
    position: relative;
    right: -2em;
    top: -1.5em;
    z-index: 1;
    margin-inline-start: auto;

    &::before,
    &::after {
      content: " ";
      display: block;
      position: absolute;
      width: calc(var(--extraCornerSize) * 2);
      height: calc(var(--extraCornerSize) * 2);
      border-right: var(--extraCornerSize) solid;
      border-top: var(--extraCornerSize) solid;
      border-top-right-radius: var(--borderRadius);
      border-color: var(--backContainerColor);
    }

    &::after {
      right: calc(-1 * var(--extraCornerSize));
      bottom: calc(-1 * var(--extraCornerSize));
    }

    &::before {
      left: calc(-1 * var(--extraCornerSize));
      top: calc(-1 * var(--extraCornerSize));
    }
  }

  &-backLink {
    transform: rotate(var(--backAngle--open));
    background-color: var(--backColor--open);
    display: flex;
    border-radius: 50%;
  }

  &-back &-backLink {
    height: 5rem;
    width: 5rem;
    padding: 1.75em;
    place-self: flex-end;

    &:hover {
      opacity: 0.9;
    }

    & svg {
      height: 100%;
      color: #2d3648;
    }
  }

  &-back--mobile {
    --backColor--open: transparent;
    --backColor--closed: transparent;
    --backAngle--open: -180deg;
    --backAngle--closed: -180deg;

    display: none;

    svg {
      height: 1em;
    }
  }

  &-back--mobile &-backLink {
    padding: 0.25em 0.5em;
  }

  &-back--mobile + &-title {
    margin-inline-start: 0.25em;
  }

  &-title {
    --ChatInlineEditable-inputPadding: var(--titleInputPadding);

    display: flex;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    transition: color 0.3s;
    padding: var(--titlePadding);
    color: #fff;
    // display: inline-flex;
    gap: 1rem;
    align-items: baseline;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-block: auto;
  }

  &-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1.5em 6em;
    overflow: auto;
    gap: 1em;
    place-self: flex-end;
    width: 100%;
  }

  &-body,
  &-back,
  &-title,
  &-backLink {
    animation-duration: var(--_animationDuration);
    animation-fill-mode: forwards;

    [data-animation-name="slidein"] & {
      animation-timing-function: ease-in;
      animation-direction: normal;
    }

    [data-animation-name="slideout"] & {
      animation-timing-function: ease-out;
      animation-direction: reverse;
    }
  }

  [data-animation-name] &-back {
    animation-name: chat-fadein-back;
  }

  [data-animation-name] &-body {
    animation-name: chat-dialog-fadein;
  }

  [data-animation-name] &-backLink {
    animation-name: chat-slidein-arrow;
  }

  [data-animation-name] &-title {
    animation-name: chat-title;
  }

  [data-is-new][data-animation-name] &-title,
  [data-is-new][data-animation-name] &-back {
    animation-name: chat-new-title;
  }
}

@keyframes chat-slidein-arrow {
  0% {
    background-color: var(--backColor--closed);
    transform: rotate(var(--backAngle--closed));
  }
  30% {
    transform: rotate(var(--backAngle--closed));
  }
  100% {
    background-color: var(--backColor--open);
    transform: rotate(var(--backAngle--open));
  }
}

@keyframes chat-dialog-fadein {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes chat-fadein-back {
  0% {
    --backContainerColor: transparent;

    transform: scale(0.6);
    padding: 0;
    padding-right: 0.5em;
    opacity: 0;
  }

  20% {
    opacity: 1;
    padding: 1em;
  }

  50% {
    --backContainerColor: transparent;
  }

  80% {
    --backContainerColor: var(--chatRootBg);
  }

  100% {
    --backContainerColor: var(--chatRootBg);

    transform: scale(1) translate(0, 0);
    opacity: 1;
    padding: 1em;
  }
}

@keyframes chat-title {
  0% {
    opacity: 0;
    transform: translateY(2em);
    color: var(--textColor);
    font-size: 2em;
    padding: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
    color: #fff;
    padding: var(--titlePadding);
  }
}

@keyframes chat-new-title {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes chat-fadein {
  0% {
    background-color: var(--tileColor);
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }

  20% {
    background-color: var(--bgColor);
  }

  100% {
    background-color: var(--bgColor);
    padding: var(--padding);
  }
}

@keyframes chat-fadeout {
  0% {
    background-color: var(--bgColor);
    padding: var(--padding);
  }

  80% {
    background-color: var(--bgColor);
  }

  100% {
    background-color: var(--tileColor);
    background-color: transparent;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

@media (max-width: 768px) {
  .TileAnimateable {
    --borderRadius: 0em;
    --padding: 0;
    --titleInputPadding: 0;

    isolation: isolate;

    &-header {
      flex-direction: row;
      align-items: center;
      gap: 0.5em;
      padding: 0.5em 0.5em 0.5em 1em;
      height: 4em;
      z-index: 1;
    }

    &-title {
      white-space: nowrap;
      font-size: 1.375em;
      padding: 0 !important;
    }

    &-back {
      display: none;
    }

    &-back--mobile {
      display: flex;
    }

    &-body {
      padding: 0em;
    }

    &-inputBlock {
      margin: 0;
      border-radius: 0em;
      padding: 0.5em;
    }

    &-useWithCaution {
      padding: 0 2em 0.5em;
    }
  }
}
