@use "../../utils";

.QuestionRow {
  --userIconSize: 4rem;
  --messageOffset: 2.5em;
  --messagePadding: 2em;
  --likeSize: 1.25em;

  display: flex;
  flex-direction: column;

  &-question,
  &-answer {
    display: flex;
    gap: 1em;
    flex: 1;
    flex-direction: column;
  }

  &-question {
    --messageBg: #131315;

    margin-inline-start: var(--messageOffset);
  }

  &-answer {
    --messageBg: #272d2e;

    margin-inline-end: var(--messageOffset);
  }

  &-line {
    margin-block: 0.5em;
    display: flex;
    gap: 0.5em;
    flex: 1;
    justify-content: flex-end;
    flex-direction: column;
  }

  &-questionBody,
  &-answerBody,
  &-answerActions,
  &-answerActionsLine,
  &-answerActionsBody {
    display: flex;
    gap: 1em;
    flex: 1;
  }

  &-answerActionsBody {
    padding-inline: var(--messagePadding);
    align-items: center;
    flex-wrap: wrap;
  }

  &-answerActionsLine {
    opacity: 0;
    transition: opacity 0.3s;
    align-items: center;
  }

  &:hover:not([data-is-typing]) &-answerActionsLine,
  &[data-is-last]:not([data-is-typing]) &-answerActionsLine {
    opacity: 1;
  }

  &-answerPager {
    display: flex;
    gap: 0.25em;
    transition: opacity 0.3s;
    opacity: 0.2;
    padding: 0.25em 0.5em;
    align-items: center;

    &:hover {
      opacity: 1;
    }

    &[data-disaled] {
      opacity: 0;
    }
  }

  &-answerPager button {
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: bold;
    transition: transform 0.3s;
    font-size: 1.2em;

    &:hover:not(:disabled) {
      color: #ccc;
      transform: scale(1.3);
    }
  }

  &-regenerate {
    --iconSize: 2.5em;

    background-color: #272d2e;
    border-radius: 50%;
    border: none;
    outline: none;
    width: 2rem;
    height: 2rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-bottom: auto;

    &:hover:not(:disabled) {
      color: #ccc;
    }
  }

  &-answerActionsBody &-regenerate {
    display: none;
    margin-inline: 0;
  }

  &[data-is-regenerating] &-regenerate {
    animation: conversation-row-refresh-rotate 1s linear infinite;
  }

  &-userIcon,
  &-fakeIcon {
    height: var(--userIconSize);
    border-radius: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-userIcon,
  &-fakeIcon,
  &-answerActionsSpacer {
    width: var(--userIconSize);
  }

  &-userIcon {
    background-color: #272d2e;
    font-size: 2em;
  }

  &-question &-userIcon {
    color: #ffb86c;
  }

  &-question &-message {
    white-space: pre-wrap;
  }

  &-answer &-userIcon {
    color: var(--accentColor);
  }

  &-message {
    position: relative;
    overflow: hidden;
    background-color: var(--messageBg);
    border-radius: 2em;
    padding: var(--messagePadding);
    font-size: 1.125rem;
    text-align: left;
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
  }

  &-line[data-has-error] &-answerBody {
    color: var(--colorError--contrast);
  }

  &-messageErrorIcon {
    width: 1.25em;
    height: 1.25em;
    background: var(--colorError);
    color: #fff;
    border-radius: 50%;
    padding: 0.125em;
    align-self: flex-start;
    flex-shrink: 0;
  }

  &-sources {
    display: flex;
    flex-direction: column;
    gap: 0.75em;
    padding: 0;
    margin: 0;
    align-items: flex-end;
    margin-inline-start: auto;
    max-width: 100%;
  }

  &-sourcesToggle {
    @extend %button--text;

    font-style: italic;
    font-size: smaller;
    margin-inline-start: auto;
  }

  &-sourceLink {
    display: flex;
    gap: 1em;
    text-decoration: none;
    align-items: center;
  }

  &-sourceItem {
    background-color: #272d2e;
    border-radius: 2em;
    padding: 0.5em 2em;
    list-style: none;
  }

  &-sourceLinkIcon {
    height: 0.75em;
    flex-shrink: 0;
  }

  &-likeButton,
  &-unlikeButton {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & > * {
      width: var(--likeSize);
    }
  }

  &-unlikeButton > * {
    transform: rotate(180deg);
  }

  &-answerActions {
    padding: 0.125em;
  }

  // override marked link styles
  &-answer a {
    --link-color: #6ba7fa;

    transition: color 0.3s;

    &:hover {
      color: #9dc4fb;
    }
  }
}

@keyframes conversation-row-refresh-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .QuestionRow {
    &-question,
    &-answer {
      --messagePadding: 1.25em;
      --messageOffset: 1.25em;

      padding-inline: 1em;
    }

    &-fakeIcon,
    &-userIcon,
    &-answerActionsSpacer {
      display: none;
    }

    &-answerPager {
      opacity: 1;
    }

    &-answerActionsBody &-regenerate {
      display: flex;
    }
  }
}
