.InlineParam {
  --colorSuccess: #cfc;
  --colorSuccess: #fff;
  --colorError: #999;

  position: relative;
  caret-color: var(--colorContrast);
  letter-spacing: 2px;
  display: inline-grid;
  grid-template-columns: 1fr;
  max-width: 100%;
  cursor: text;
  padding-block: 0.125em;

  &[data-multiline] {
    display: grid;
  }

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &[data-missing] {
    color: var(--colorError);
  }

  &:not([data-missing]) {
    color: var(--colorSuccess);
  }

  &-value {
    min-height: 100%;
    color: var(--colorSuccess);
    grid-row-start: 1;
    grid-column-start: 1;
    outline-color: #999;
    outline-width: 1px;
    outline-offset: 2px;
  }

  &-placeholder {
    display: none;
    grid-row-start: 1;
    grid-column-start: 1;
    font-style: italic;
  }

  &[data-missing] &-placeholder {
    display: block;
  }

  &[data-missing] &-placeholder,
  &:not([data-missing]) &-value {
    background-color: var(--chatRootBg);
    box-shadow: 1px 1px 2px 1px #555;
  }

  &[data-edit-mode] &-value {
    opacity: 1;
  }

  &-value,
  &-placeholder {
    padding: 0 0.5em;
    border-radius: 1em;
  }
}
