.OverviewSearch {
  --boxPadding: var(--OverviewSearch-boxPadding, 0.5em);
  --toggleWidth: var(--OverviewSearch-toggleWidth, 1.5em);
  --togglePadding: var(--OverviewSearch-togglePadding, 0.75em);
  --iconSize: var(--OverviewSearch-iconSize, 1.5em);
  --width: min(432px, 100%);

  display: flex;
  flex-direction: column;
  gap: 1em;
  color: #d9d9d9;
  width: var(--width);
  animation: conversation-search-collapse 0.3s;
  animation-fill-mode: both;

  &-box {
    position: relative;
    width: min-content;
    height: 100%;
    border-radius: 2em;
    background-color: transparent;
    color: #000;
    padding: var(--boxPadding);
    outline: none;
    display: flex;
    width: 0;
    min-width: 0;
    transition:
      min-width 0.3s,
      padding 0.3s,
      background-color 0.2s;
  }

  &-input {
    line-height: 1.2;
    outline: none;
    background-color: transparent;
    border: none;
    color: inherit;
    font-size: inherit;
    flex: 1;
    min-width: 0em;
    padding: 0;
    opacity: 0;
    transition:
      padding 0.3s,
      opacity 0.2s;
  }

  &-toggle,
  &-reset {
    border-radius: 50%;
    border: none;
    outline: none;
    display: flex;
    padding: var(--togglePadding);
    align-items: center;

    > * {
      height: 100%;
      min-height: var(--iconSize);
    }
  }

  &-toggle {
    background-color: #1a1a1a;
    height: 100%;
    aspect-ratio: 1;
    justify-content: center;
  }

  &-reset {
    color: var(--colorDefault);
    background-color: transparent;
  }

  &[data-expanded] {
    animation: conversation-search-expand 0.3s;
  }

  &[data-expanded] &-box {
    min-width: 6em;
    width: 100%;
    height: 100%;
    padding-inline: var(--boxPadding);
    background-color: #fff;
  }

  &[data-expanded] &-input {
    padding-inline-start: 0.5em;
    opacity: 1;
  }
}

@keyframes conversation-search-expand {
  from {
    width: 0;
  }
  to {
    width: var(--width);
  }
}

@keyframes conversation-search-collapse {
  from {
    width: var(--width);
  }
  to {
    width: 0;
  }
}
