.MultiChoice {
  line-height: 2;
  margin: auto;

  &-dropdown {
    overflow-y: auto;
    background: var(--dropdownBg);
    min-width: 100px;
    border-radius: 8px;
    outline: none;
    display: flex;
    flex-direction: column;
    padding: 2em 1.5em;
  }

  &-row {
    line-height: 2;
    cursor: default;
    color: var(--colorContrast);
    display: flex;
    gap: 0.75em;
  }
}
