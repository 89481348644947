@use "../../utils";

.PromptsIndex {
  --colCount: 5;
  --borderRadius: 3em;

  container-type: inline-size;
  container-name: conversations;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1em;
  position: relative;
  overflow: auto;
  isolation: isolate;

  // temporarily hide the Quickstarts section
  // see: https://ligadigital.atlassian.net/browse/LGDA-177
  // TODO: remove this when come back to the Quickstarts implementation
  #QuickstartsTitle,
  &-quickstarts {
    display: none !important;
  }

  &-scrollable {
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 2em 2em;
    scrollbar-gutter: stable;
  }

  &-header {
    font-size: 1.25em;
    display: flex;
    gap: 1em;
    align-items: center;
    position: sticky;
    padding: 1em;
    top: 0;
    background-color: var(--chatRootBg);
    z-index: 1;
    transition:
      transform 0.3s ease-in-out,
      background-color var(--appBgSpeed);
  }

  &[data-no-side-controls] &-header {
    transform: translateY(-100%);
  }

  &[data-mobile][data-no-side-controls] &-actions {
    transform: translateY(150%);
  }

  &-logo {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    opacity: 1;
  }

  &-logoLink {
    background: none;
    outline: none;
    border: none;
    padding: 0;
    font-size: inherit;
    display: flex;
    align-items: center;
  }

  &-logoImage {
    height: 2em;
    color: #fff;
    margin-bottom: -0.2em;
  }

  &-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1em;
  }

  &-quickstarts {
    padding: 1em;
  }

  &-actions {
    --ActionsDropdown-iconSize: 4em;

    margin-left: auto;
    transition: background-color 0.3s;
    background-color: transparent;
    border-radius: var(--borderRadius);
    padding: 0.5em 0.75em 1em;
  }

  &-actions {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 2em;
    border-radius: var(--borderRadius);
    background-color: #eee;
    color: #000;
    transition: background-color 0.3s;

    &:hover {
      background-color: #ddd;
    }
  }

  &-quickstarts,
  &-list {
    list-style: none;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(var(--colCount), 1fr);
    gap: 1.5em;
    margin: 0;
    box-sizing: border-box;
  }

  &-filterTrigger {
    display: flex;
    gap: 0.5em;
    align-items: center;
    cursor: pointer;
    font-size: 1.125em;
    padding: 0.5em;
    border-radius: var(--borderRadius);

    svg {
      height: 1em;
    }
  }

  &-filters {
    font-size: 1rem; // TODO: remove
    margin-inline-start: auto;
    display: flex;
    flex-direction: column;
    gap: 0.125em;

    label {
      display: flex;
      gap: 0.5em;
    }
  }

  &-removeFilter {
    @extend %button--text;

    svg {
      height: 1em;
    }
  }

  &-item {
    --borderRadius: 3em;
    --bgColor: #eee;
    --textColor: #000;

    overflow: auto;
    border-radius: var(--borderRadius);
    background-color: #eee;
    color: var(--textColor);
    height: 100%;

    &:hover {
      opacity: 0.9;
    }
  }

  [aria-busy="true"] &-item {
    opacity: 0.5;
  }

  &-item[data-should-highlight] {
    background-color: var(--accentColor);
  }

  &-reloading,
  &-loading {
    padding: 1em;
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 1em;
  }

  &-reloading {
    position: absolute;
    transform: translateX(-50%);
    margin-left: 50%;
  }

  &-gridHeader {
    margin-top: 2em;
  }

  &-gridHeader {
    padding-inline: 1em;
    color: #d9d9d9;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &-gridTitle {
    font-size: 1.125em;
    font-weight: 800;
    line-height: 2.4em;
  }

  &-selectedFilters {
    width: 100%;
    display: flex;
    gap: 1em;
    padding-block: 1em;
  }

  &-selectedFilter {
    padding: 1em;
    background-color: var(--color-70);
    border-radius: 3em;
    display: flex;
    gap: 0.5em;
    cursor: default;
  }

  &-tile {
    --Tile-fontSize: 1.25em;
  }
}

@container conversations (max-width: 1340px) {
  .PromptsIndex {
    &-quickstarts {
      --colCount: 5;
    }

    &-list {
      --colCount: 3;
    }

    &-header[data-search-expanded] &-logo {
      opacity: 0;
    }
  }
}

@container conversations (max-width: 768px) {
  .PromptsIndex {
    &-quickstarts {
      --colCount: 3;
    }

    &-list {
      --colCount: 1;
    }

    &-search {
      --OverviewSearch-boxPadding: 0.25em;
      --OverviewSearch-iconPadding: 0.75em;

      padding: 0;
      margin-inline-end: 5em;
    }

    &-gridHeader {
      margin-top: 1em;
    }

    &-scrollable {
      margin: 0 0 1em;
    }

    &-header {
      padding: 0.5em;
      font-size: 0.875em;
    }

    &-logout {
      --iconSize: 1.5em;

      @extend %button--text;

      margin-inline-start: auto;
      padding: 0.5em;
    }
  }
}

@media only screen and (max-width: 768px) {
  .PromptsIndex {
    &-actions {
      position: fixed;
      bottom: 1em;
      right: 1em;
      transition: transform 0.3s ease-in-out;
      box-shadow: 0 0 0.5em #8888;
    }
  }
}
