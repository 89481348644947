.SenderLayout {
  --bg: var(--SenderLayout-bg, #fff);
  --color: var(--SenderLayout-color, #333);

  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;
  padding: 0.5em 0.75em;
  border-radius: 3rem;
  background-color: var(--chatRootBg);

  &-spinner {
    height: 3.5rem;
    width: 3.5rem;
  }

  &-field {
    color: var(--color);
    background: var(--bg);
    padding: 1rem;
    border-radius: 3rem;
    flex: 1;
    transition:
      background-color 0.3s,
      color 0.3s;
  }

  &-send {
    background-color: var(--sendColor);
    color: var(--sendTextColor, currentcolor);
    border: 0;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
    align-items: center;

    > * {
      height: 2em;
    }
  }
}

@media (max-width: 768px) {
  .SenderLayout {
    --sendColor: var(--accentColor);
    --sendTextColor: #191d1e;

    &-field {
      padding: 0.25em 0.75em;
    }
  }
}
