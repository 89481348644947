.UserPicture {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;

  &-img {
    position: absolute;
    inset: 0;
    border-radius: inherit;
    width: 100%;
  }

  &[data-status] {
    opacity: 1;
  }
}
