.ActionsDropdown {
  --newItemsSpacing: 0.75em;
  --iconSize: var(--ActionsDropdown-iconSize, 2em);
  --iconPadding: var(
    --ActionsDropdown-iconPadding,
    calc(0.25 * var(--iconSize))
  );
  --overlayColor: var(--chatRootBg);
  --overlayOpacity: 0.9;
  --iconColor--default: #eee;
  --iconColor--highlight: var(--accentColor);
  --iconColor: var(--iconColor--default);

  flex-direction: column;
  gap: var(--newItemsSpacing);
  display: flex;
  isolation: isolate;
  position: relative;
  width: var(--iconSize);
  height: var(--iconSize);

  &:not([data-reverse]) {
    --initialDirection: -1;
  }

  &[data-reverse] {
    --initialDirection: 1;
  }

  &-wrapper {
    position: absolute;
    right: 0;
  }

  &:not([data-reverse]) &-wrapper {
    top: 0;
  }

  &[data-reverse] &-wrapper {
    bottom: 0;
  }

  & > &-link {
    z-index: 2;
  }

  &-overlay {
    display: none;
  }

  &-body {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--newItemsSpacing);

    & > * {
      pointer-events: none;
    }
  }

  &[data-expanded] &-body > * {
    pointer-events: all;
  }

  &[data-expanded] &-trigger {
    display: none;
    transition: scale(0);
  }

  &[data-expanded] &-overlay {
    display: block;
    position: fixed;
    inset: 0;
    background-color: var(--overlayColor);
    animation: ActionsDropdown-fadeIn 0.3s;
    opacity: var(--overlayOpacity);
  }

  &[data-expanded] &-options {
    max-height: 100%;
    transform: translateY(0);
    opacity: 1;

    & > * {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &-options {
    display: flex;
    position: relative;
    gap: var(--newItemsSpacing);
    flex-direction: column;
    z-index: 0;
    overflow: hidden;
    max-height: 0;
    transition:
      max-height 0.3s,
      transform 0.3s,
      opacity 0.15s;
    transform: translateY(calc(var(--initialDirection) * var(--iconSize)));
    opacity: 0;

    & > * {
      transition:
        transform 0.3s,
        opacity 0.15s;
      transform: translateY(calc(var(--initialDirection) * 100%));
      opacity: 0;
    }
  }

  &-options &-options {
    margin-top: var(--newItemsSpacing);
  }

  &-linkText {
    opacity: 0;
    transition:
      opacity 0.3s ease-out,
      max-width 0.3s ease-out;
    color: #fff;
    max-width: 0;
    overflow: hidden;
    white-space: nowrap;
    margin-inline-start: auto;
  }

  &[data-expanded] &-linkText {
    opacity: 1;
    width: auto;
    max-width: 100%;
  }

  &-link {
    display: flex;
    gap: 1em;
    align-items: center;
    padding-inline-start: 1em;
    text-decoration: none;
    transition:
      transform 0.3s,
      opacity 0.15s;
  }

  &-linkIcon {
    width: var(--iconSize);
    height: var(--iconSize);

    background-color: var(--iconColor);

    color: var(--chatRootBg);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    padding: var(--iconPadding);
    transition:
      background-color 0.3s,
      color 0.3s;
  }

  &-link[data-highlight] {
    --iconColor: var(--iconColor--highlight);
  }
}

@keyframes ActionsDropdown-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: var(--overlayOpacity);
  }
}
