.PromptsForm {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  gap: 1em;
  line-height: 1.5;

  &-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    gap: 1em;
    line-height: 1.5;
  }

  [type="submit"] {
    border: 1px solid transparent;
    border-radius: 0.5em;
    transition:
      background-color 0.3s,
      opacity 0.3s;
    background-color: var(--accentColor);
    color: var(--colorDefault);
    text-transform: uppercase;
    font-size: 1em;
    padding: 0.5em 1em;

    &:hover:not(:disabled) {
      opacity: 0.8;
    }
  }

  button:disabled {
    opacity: 0.5;
  }

  textarea,
  input {
    padding: 1em;
    line-height: 1.5;
    border: 1px solid transparent;
    border-radius: 0.5em;
    background-color: #272d2e;
    color: #fff;
  }

  textarea {
    resize: none;
  }

  label {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  &-label {
    font-size: 1.125em;
    font-weight: 800;
  }

  label:has(input[type="radio"]) {
    flex-direction: row;
  }

  label:has(input[type="radio"]) &-label {
    padding-block: 0.5em;
  }

  &-extras {
    display: grid;
    grid-template-columns: repeat(2, minmax(10em, 1fr));
    gap: 1em;
  }

  &-actions {
    display: flex;
    gap: 1em;
    margin-block-start: auto;
    justify-content: flex-end;
    align-items: center;
  }

  &[data-submitting] [type="submit"] {
    cursor: wait;
  }

  &-delete {
    margin-inline-end: auto;
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    padding: 0.5em;

    &:hover:not(:disabled) {
      opacity: 0.8;
    }
  }
}

@media (max-width: 768px) {
  .PromptsForm {
    padding: 1em;
  }
}
