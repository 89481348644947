.LoginModal {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
  flex: 1;
  color: #d9d9d9;
  margin-top: -5em;
  font-size: 1.25em;

  &-logo {
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  &-form {
    max-width: 400px;
    width: 100%;
    gap: 1em;
    display: flex;
    flex-direction: column;
    border-radius: 3rem;
    background-color: var(--chatRootBg);
    padding: 2em;
  }

  button[type="submit"] {
    margin-top: 2em;
    cursor: pointer;
    padding: 1em 2em;
    color: var(--colorDefault);

    background-color: var(--accentColor);
    border: 0;
    border-radius: 3em;
    padding: 0.5em 1em;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: inherit;

    &:hover:not(:disabled) {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.5;
      cursor: wait;
    }
  }

  &-error {
    color: #ff9999;
    font-size: 0.75em;
    text-align: center;
    padding: 0.5em;
  }

  &-input {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    label {
      font-size: 0.8em;
      color: #828282;
    }

    input {
      color-scheme: light;
      color: #333;
      background: #fff;
      padding: 1rem;
      border-radius: 3rem;
      flex: 1;
    }
  }
}

@media (max-width: 768px) {
  .LoginModal {
    background-color: var(--chatRootBg);
  }
}
