.ChatInlineEditable {
  --inputPadding: var(
    --ChatInlineEditable-inputPadding,
    0.5em 0.125em 0.5em 0.75em
  );

  display: flex;
  align-items: baseline;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  &-editable {
    position: relative;
    min-width: 3em;
    padding: var(--inputPadding);
    border-radius: 3rem;
    transition:
      background-color 0.3s,
      color 0.3s;
  }

  &-placeholder {
    // do not wrap but respect white spaces in order to be inline with the input
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    visibility: hidden;
  }

  &:not([data-has-value]) &-placeholder {
    // placeholder serves 3 purposes:
    // 1. to provide a fallabck text when the input is empty (standard use case)
    // 2. replicate the input's text style (font, color, etc) when the input is not empty,
    // so it allows the input to take the same size as the text.
    // This is needed cause the native input does not shrink/grow properly when text changes.
    // 3. display a whitespace when the input is empty, so the input does not collapse to 0 width.
    visibility: visible;
    color: #828282;
  }

  &[data-edit-mode] &-editable {
    border: 1px solid #333;
    color-scheme: light;
    color: #333;
    background: #fff;
  }

  &-input {
    position: absolute;
    inset: 0;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    background: transparent;
    border: none;
    outline: none;
    padding: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-save,
  &-cancel,
  &-edit {
    display: inline-flex;
    background: transparent;
    border: 0;
    opacity: 1;
    transition: opacity 0.3s;

    > * {
      height: 100%;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &-edit {
    height: 1rem;
  }

  &-save,
  &-cancel {
    height: 1.5rem;
  }
}
