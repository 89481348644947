@use "../utils";

.Overview {
  --colCount: 5;
  --mediumSpan: 2;
  // TODO: move above
  --borderRadius: 3em;

  container-type: inline-size;
  container-name: conversations;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1em;
  position: relative;
  overflow: auto;
  isolation: isolate;

  &-scrollable {
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 2em 2em;
    scrollbar-gutter: stable;
  }

  &-header {
    font-size: 1.25em;
    display: flex;
    gap: 1em;
    align-items: center;
    position: sticky;
    padding: 1em;
    top: 0;
    background-color: var(--chatRootBg);
    z-index: 1;
    transition:
      transform 0.3s ease-in-out,
      background-color var(--appBgSpeed);
  }

  &[data-no-side-controls] &-header {
    transform: translateY(-100%);
  }

  &[data-mobile][data-no-side-controls] &-actions {
    transform: translateY(150%);
  }

  &-logo {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    opacity: 1;
  }

  &-logoLink {
    background: none;
    outline: none;
    border: none;
    padding: 0;
    font-size: inherit;
    display: flex;
    align-items: center;
  }

  &-logoImage {
    height: 2em;
    color: #fff;
    margin-bottom: -0.2em;
  }

  &-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1em;
  }

  &-actions {
    --ActionsDropdown-iconSize: 4em;

    margin-left: auto;
    transition: background-color 0.3s;
    background-color: transparent;
    border-radius: var(--borderRadius);
    padding: 0.5em 0.75em 1em;
  }

  &-item:hover {
    opacity: 0.9;
  }

  &-list {
    list-style: none;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(var(--colCount), 1fr);
    gap: 1.5em;
    margin: 0;
    box-sizing: border-box;
  }

  &-item {
    --borderRadius: 3em;
    --bgColor: #eee;
    --textColor: #000;

    overflow: auto;
    border-radius: var(--borderRadius);
    background-color: #eee;
    color: var(--textColor);
    height: 100%;
  }

  &-item--medium {
    grid-column: span var(--mediumSpan);
  }

  [aria-busy="true"] &-item {
    opacity: 0.5;
  }

  &-item--large {
    grid-row: span 2;
  }

  &-item[data-should-highlight] {
    background-color: var(--accentColor);
  }

  &-reloading,
  &-loading {
    padding: 1em;
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 1em;
  }

  &-reloading {
    position: absolute;
    transform: translateX(-50%);
    margin-left: 50%;
  }

  &-gridTitle {
    margin-top: 2em;
  }

  &-searchLabel,
  &-gridTitle {
    font-size: 1.125em;
    padding-inline: 1em;
    font-weight: 800;
    color: #d9d9d9;
    line-height: 2.4em;
  }

  &-animationContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }

  &-content {
    view-transition-name: content;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    position: absolute;
    inset: 0;
    pointer-events: none;

    > * {
      pointer-events: auto;
      position: absolute;
      inset: 0;
    }
  }

  &-content-enter-active:not(.in-chat) {
    animation-name: slide-out;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
  }

  &-content-enter-active.in-chat {
    animation-name: slide-in;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
  }

  &-content-exit-active:not(.in-chat) {
    animation-name: slide-in;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
  }

  &-content-exit-active.in-chat {
    animation-name: slide-out;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100vw);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@container conversations (max-width: 1340px) {
  .Overview {
    &-list {
      --colCount: 3;
    }

    &-header[data-search-expanded] &-logo {
      opacity: 0;
    }

    &-new {
      --ActionsDropdown-iconSize: 3em;
    }
  }
}

@container conversations (max-width: 768px) {
  .Overview {
    &-list {
      --colCount: 1;
      --mediumSpan: 1;
    }

    &-search {
      --OverviewSearch-boxPadding: 0.25em;
      --OverviewSearch-iconPadding: 0.75em;

      padding: 0;
      margin-inline-end: 5em;
    }

    &-gridTitle {
      margin-top: 1em;
    }

    &-scrollable {
      margin: 0 0 1em;
    }

    &-header {
      padding: 0.5em;
      font-size: 0.875em;
    }

    &-logout {
      --iconSize: 1.5em;

      @extend %button--text;

      margin-inline-start: auto;
      padding: 0.5em;
    }
  }
}

@media only screen and (max-width: 768px) {
  .Overview {
    &-actions {
      position: fixed;
      bottom: 1em;
      right: 1em;
      transition: transform 0.3s ease-in-out;
      box-shadow: 0 0 0.5em #8888;
    }
  }
}
